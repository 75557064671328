@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #262b33;
}

:root {
    --animate-delay: 0.5s;
  }